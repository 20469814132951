const mobileMenu = () => {
  	// Hamburger menu
  const $body = document.querySelector('body');
  const $hamburger = document.querySelector('.site-hamburger');

  $hamburger && $hamburger.addEventListener( "click", () => {
    $body.classList.toggle('menu-is-open');
  });
}

export default mobileMenu;
